import {Content as PostPicContent} from "@/components/SectionPostPic.vue"
import ImgData from "@/models/ImgData.js"

export default class Fuehlen {
  /**
   * @param {Object} x
   * @param {String} x.name
   * @param {String[]} x.descrPrePic - paragraphs, rendered with v-html
   * @param {ImgData} x.imgData
   * @param {PostPicContent[]} x.postPicContent
   */
  constructor(x) {
    Object.assign(this, x)
  }
}

// Maps /fuehlen/:name to content
export const content = {
  samstag: new Fuehlen({
    name: "Samstag",
    descrPrePic: [
      "Frisch gewaschene Klassiker der Musikgeschichte."
    ],
    imgData: new ImgData("/img/samstag1.jpg", "Flyer für das Event Z Stardust"),
    postPicContent: [
      new PostPicContent({
        header: "5 believes in miracles",
        headingLevel: 2,
        paragraphs: [
          "DJ's ab 21 Uhr<br>DJ Soulstar<br>DJ Raul Rincon<br>DJ Wim Gutmann"
        ]
      }),
      new PostPicContent({
        header: "Ramones believe in miracles",
        headingLevel: 3,
        paragraphs: [
          "I used to be on an endless run<br>Believed in miracles cos I`m one<br>I`ve been blessed with the power to survive<br>After all these years i am still alive"
        ]
      })
    ]
  })

  /*
  sonntag: new Fuehlen({
    name: "Sonntag",
    descrPrePic: [
      "Erstrangig frühstücken im 5.<br>Viel mehr als das tägliche Brot."
    ],
    imgData: new ImgData("/img/sonntag1.jpg", "Verschiedene Frühstücks-Leckereien"),
    postPicContent: [
      new PostPicContent({
        header: "Frühstücksfilmmatinee",
        headingLevel: 2,
        paragraphs: [
          "Legendäre Klassiker der Filmgeschichte auf Zelluloid gebannt.<br>Jeden ersten Sonntag im Monat im 5 Gourmet Restaurant (1.OG).",
          "Frühstück ab 10 Uhr<br>Filmvorführung 11 Uhr",
          "In der Bar/Lounge (EG) können Sie wie gewohnt unser Frühstück à la carte geniessen."
        ]
      })
    ]
  })
  */
}
