<template>
  <section class="fuehlen">
    <h1 class="fuehlen__h1">
      {{ content.name }}
    </h1>
    <ParagraphsPrePic :paragraphs="content.descrPrePic" />
    <ImgSingle
      class="fuehlen__img"
      :img="content.imgData"
    />
    <SectionPostPic
      v-for="x in content.postPicContent"
      :key="x.header"
      :content="x"
    />
  </section>
</template>

<script>
import {content} from "@/models/Fuehlen.js"
import {getMeta} from "@/util/route.js"
import ImgSingle from "@/components/ImgSingle.vue"
import ParagraphsPrePic from "@/components/ParagraphsPrePic.vue"
import SectionPostPic from "@/components/SectionPostPic.vue"

export default {
  name: "Fuehlen",

  components: {
    ImgSingle,
    ParagraphsPrePic,
    SectionPostPic
  },

  computed: {
    fuehlenKey() {
      return getMeta(this.$route.matched, "fuehlenKey")
    },

    content() {
      return content[this.fuehlenKey]
    }
  }
}
</script>

<styles lang="scss">
@use "app";
@use "txt";

.fuehlen {
  margin-top: app.$margin-to-nav;
  margin-bottom: txt.$blockmargin;
}

.fuehlen__h1 {
  @include txt.sitehead;
  @include txt.bodypadding-h;
}

.fuehlen__img {
  margin-top: 3em;
}
</styles>
